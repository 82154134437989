:root {
  /* font-family: ; */
  /* Primary Colors */
  --p-90: #070f2c;
  --p-80: #0f1f57;
  --p-70: #162e83;
  --p-60: #1e3eae;
  --p-50: #254dda;
  --p-40: #5171e1;
  --p-30: #7c94e9;
  --p-20: #a8b8f0;
  --p-10: #d3dbf8;

  /* Text Colors */
  --text-primary: #000041;
  --text-secondary: #66668d;
  --text-tertiary: #9999b3;

  /* Fill Colors */
  --fill-highlight-only: #fbfbfe;
  --fill-selection-only: #f6f8fe;

  /* Neutral Colors */
  --white: #ffffff;
  --black: #000000;

  /* Border Colors */
  --stroke-tabs-sections: #e5e9fb;
  --stroke-buttons-input: #d3dbf8;

  /* Link Colors */
  --link-rest: #0f72ee;
  --link-hover: #074da6;

  /* Positive Colors */
  --g-90: #0b2419;
  --g-80: #164832;
  --g-70: #206b4c;
  --g-60: #2b8f65;
  --g-50: #36b37e;
  --g-40: #5ec298;
  --g-30: #86d1b2;
  --g-20: #afe1cb;
  --g-10: #d7f0e5;

  /* Warning Colors */
  --w-90: #311f04;
  --w-80: #613e07;
  --w-70: #925e0b;
  --w-60: #c27d0e;
  --w-50: #f39c12;
  --w-40: #f5b041;
  --w-30: #f5b041;
  --w-20: #fad7a0;
  --w-10: #fdebd0;

  /* Info Colors */
  --i-90: #0a1e2c;
  --i-80: #153d58;
  --i-70: #1f5b83;
  --i-60: #2a7aaf;
  --i-50: #3498db;
  --i-40: #5dade2;
  --i-30: #85c1e9;
  --i-20: #aed6f1;
  --i-10: #d6eaf8;

  /* Dark Colors */
  --d-40: #888889;
  --d-50: #6A6A6C;


  /* Font Size */
  --font-size-h1: 64px;
  --font-size-h2: 48px;
  --font-size-h3: 36px;
  --font-size-h4: 28px;
  --font-size-h5: 24px;
  --font-size-h6: 18px;
  --font-size-h7: 16px;
  --font-size-h8: 14px;

  --font-size-28: 28px;
  --font-size-24: 24px;
  --font-size-20: 20px;
  --font-size-16: 16px;
  --font-size-18: 18px;
  --font-size-14: 14px;

  /* Line Height */
  --line-height-72: 72px;
  --line-height-56: 56px;
  --line-height-44: 44px;
  --line-height-32: 32px;
  --line-height-30: 30px;
  --line-height-28: 28px;
  --line-height-26: 26px;
  --line-height-24: 24px;
  --line-height-23: 23px;
  --line-height-22: 22px;
  --line-height-20: 20px;
  --line-height-18: 18px;

  /* Font Weight */
  --font-weight-400: 400;
  --font-weight-500: 500;
  --font-weight-600: 600;

  /* Computer Screen */
  --max-screen-width: 1300px;
}

.h1-v1 {
  font-size: var(--font-size-h1);
  font-weight: var(--font-weight-600);
  line-height: var(--line-height-72);
  letter-spacing: -0.4px;
}
.h2-v1 {
  font-size: var(--font-size-h2);
  font-weight: var(--font-weight-600);
  line-height: var(--line-height-56);
  letter-spacing: -0.4px;
}
.h3-v1 {
  font-size: var(--font-size-h3);
  font-weight: var(--font-weight-600);
  line-height: var(--line-height-44);
  letter-spacing: -0.4px;
}
.h4-v1 {
  font-size: var(--font-size-h4);
  font-weight: var(--font-weight-600);
  line-height: var(--line-height-32);
  letter-spacing: -0.4px;
}
.h5-v1 {
  font-size: var(--font-size-h5);
  font-weight: var(--font-weight-600);
  line-height: var(--line-height-32);
  letter-spacing: -0.2px;
}
.h6-v1 {
  font-size: var(--font-size-h6);
  font-weight: var(--font-weight-600);
  line-height: var(--line-height-24);
  letter-spacing: -0.2px;
}
.h7-v1 {
  font-size: var(--font-size-h7);
  font-weight: var(--font-weight-600);
  line-height: var(--line-height-20);
  letter-spacing: -0.2px;
}
.h8-v1 {
  font-size: var(--font-size-h8);
  font-weight: var(--font-weight-600);
  line-height: var(--line-height-18);
  letter-spacing: -0.2px;
}

.paragraph1-v1 {
  font-size: var(--font-size-20);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-28);
  letter-spacing: 0.2px;
}
.paragraph2-v1 {
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-24);
  letter-spacing: 0.2px;
}
.paragraph3-v1 {
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-20);
  letter-spacing: 0.2px;
}
.text-center {
  text-align: center;
}
.strong-text {
  font-weight: var(--font-weight-600);
}

@media screen and (max-width: 800px) {
  .h1-v1 {
    font-size: var(--font-size-h2);
    font-weight: var(--font-weight-600);
    line-height: var(--line-height-56);
    letter-spacing: -0.4px;
  }
  .h2-v1 {
    font-size: var(--font-size-h3);
    font-weight: var(--font-weight-600);
    line-height: var(--line-height-44);
  }
  .h3-v1 {
    font-size: var(--font-size-h4);
    font-weight: var(--font-weight-600);
    line-height: var(--line-height-32);
    letter-spacing: -0.4px;
  }
  .h4-v1 {
    font-size: var(--font-size-h5);
    font-weight: var(--font-weight-600);
    line-height: var(--line-height-32);
    letter-spacing: -0.2px;
  }

  .paragraph1-v1 {
    font-size: var(--font-size-16);
    font-weight: var(--font-weight-400);
    line-height: var(--line-height-24);
    letter-spacing: 0.2px;
  }
}
